import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Warmup:  Banded Good Mornings 3×12  Lateral Band Hold 3x:30/side`}</em></p>
    <p>{`Dumbbell Deadlifts 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`For time:`}</p>
    <p>{`60-Calorie Assault Bike`}</p>
    <p>{`50-K2E’s`}</p>
    <p>{`40-DB Burpee Deadlifts (50/35’s)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      